/**** COLORS ****/
:root {
  --dark1: #2E3440;
  --dark2: #3B4252;
  --dark3: #434C5E;
  --dark4: #4C566A;

  --light1: #D8DEE9;
  --light2: #E5E9F0;
  --light3: #ECEFF4;
  --light4: #FFFFFF;

  --blue1: #8FBCBB;
  --blue2: #88C0D0;
  --blue3: #81A1C1;
  --blue4: #5E81AC;

  --red: #BF616A;
  --orange: #D08770;
  --yellow: #EBCB8B;
  --green: #A3BE8C;
  --violet: #B48EAD;
}

/**** GENERAL ****/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
*:before,
*:after {
  box-sizing: inherit;
}
body {
  font-size: 62.5%;
  font-family: 'Open Sans', sans-serif;
  background-color: var(--dark1);
}
html,
body,
#root {
  width: 100%;
  height: 100%;
}

/**** NAV ****/
.main-nav {
  display: grid;
  grid-template-columns: [start] auto [header-start] 400px [header-slant] 48px [header-mid] 752px [header-end] auto [end];
  height: 64px;
  position: fixed;
  padding-top: 16px;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 10;
  background: rgba(var(--dark1, 0.9));
}
.main-nav--transparent {
  background: transparent;
}
.main-nav__title {
  border-bottom: 1px solid var(--light3);
  padding-bottom: 16px;
  position: relative;
  padding-right: 8px;
  grid-column: start / header-slant;
  font-family: 'Space Mono', sans-serif;
}
.main-nav__title a {
  text-transform: uppercase;
  display: inline-block;
  background-color: var(--light3);
  color: var(--dark1);
  line-height: 32px;
  padding: 0 16px;
  height: 32px;
  width: 100%;
  text-align: right;
  text-decoration: none;
}
.main-nav__title span {
  color: rgba(0, 0, 0, 0.6);
  font-weight: normal;
}
.main-nav__title .separator {
  color: var(--dark1);
  position: relative;
  top: -2px;
}
.main-nav__edge {
  position: absolute;
  right: -24px;
  top: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 32px 32px 0 0;
  border-color: var(--light3) transparent transparent transparent;
}
.main-nav__slant {
  grid-column: header-slant / header-mid;
  width: 48px;
  height: 1px;
  transform: rotate(-45deg) scaleX(1.4);
  transform-origin: bottom left;
  position: relative;
  background: var(--light3);
  top: 48px;
  right: -1px;
}
.main-nav__links--desktop {
  grid-column: header-mid / end;
  display: grid;
  grid-template-columns: [nav-start] 752px [nav-end] auto [end];
  border-top: 1px solid var(--light3);
}
.main-nav__links {
  grid-column: nav-start / nav-end;
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  font-family: 'Space Mono', monospace;
}
.main-nav__links a {
  display: inline-flex;
  height: 100%;
  align-items: center;
  padding: 0 24px;
  color: var(--light3);
  text-decoration: none;
}
.main-nav__links li {
  padding: 0 6px;
  font-size: 1.3em;
  text-transform: uppercase;
  cursor: pointer;
  position: relative;
  display: inline-flex;
  align-items: center;
  transition: color 0.2s;
}
.main-nav__links a:hover li {
  color: var(--dark1);
}
.main-nav__links li:before {
  content: '';
  width: 100%;
  height: 0;
  background-color: var(--yellow);
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1; 
  transition: height 0.2s; 
}
.main-nav__links a:hover li:before {
  height: 100%;
}
.main-nav__links .index {
  color: var(--yellow);
  margin-right: 12px;
  font-size: 0.8em;
  transition: color 0.2s;
}
.main-nav__links a:hover .index {
  color: var(--dark4);
}
/* .main-nav__links--mobile {
  grid-column: header-end / end;
  display: none;
  border-top: 1px solid var(--light3);

  .main-nav__links--menu {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding-right: 8px;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
  }

  .main-nav__links--menu img {
    height: 24px;
    width: 24px;
  }

  .main-nav__links {
    flex-flow: column nowrap;
    align-items: flex-end;
    justify-content: flex-start;
    position: absolute;
    top: 72px;
    right: -192px;
    z-index: 10;
    transition: all 0.2s;
  }

  .main-nav__links.open {
    right: 0;
    transition: all 0.2s;
  }

  .main-nav__links--overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    margin: auto;
    z-index: 9;
    background: rgba(0, 0, 0, 0.6);
  }

  .main-nav__links li {
    width: 144px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    padding: 8px 0;
    color: var(--dark1);
  }

  .main-nav__links .index {
    position: absolute;
    left: -16px;
    top: 0;
    bottom: 0;
    margin: auto;
    display: inline-flex;
    align-items: center;
    color: var(--dark4);
  }

  .main-nav__links li:before {
    content: '';
    width: 8px;
    height: 100%;
    background-color: var(--yellow);
    position: absolute;
    right: -24px;
    top: 0;
    left: auto;
    bottom: 0;
    z-index: -1; 
    transition: width 0.2s; 
  }

  .main-nav__links a:hover li:before {
    width: calc(100% + 48px);
  }

  .main-nav__links li:after {
    content: '';
    width: calc(100% + 48px);
    height: 100%;
    background-color: var(--light3);
    position: absolute;
    right: -24px;
    top: 0;
    left: auto;
    bottom: 0;
    z-index: -2;
  }
} */

@media all and (max-width: 1200px) {
  .main-nav {
    grid-template-columns: [start] auto [header-slant] 48px [header-end] 96px [end];
  }
  .main-nav__links--desktop {
    display: none;
  }
  .main-nav__links--mobile {
    display: block;
  }
  .main-nav__slant {
    grid-column: header-slant / header-end;
  }
  .main-nav__title a {
    text-align: left;
  }
  .home-header__contents {
    align-items: center;
  }
}

@media all and (max-width: 600px) {
  .main-nav__title span {
    display: none;
  }
}

/**** SECTIONS ****/
.section {
  max-width: 1200px;
  width: 100%;
  align-self: center;
  margin: 64px auto;
}

/**** HOME ****/
.home-container {
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  height: 100vh;
}
.home-header {
  color: var(--light3);
  display: flex;
  flex: 1;
  align-items: stretch;
  justify-content: center;
  overflow: hidden;
  background: linear-gradient(rgba(10,10,10,.2),rgba(0,0,0,.35)), repeating-linear-gradient(0, transparent, transparent 2px, rgba(0, 0, 0, 0.4) 3px, rgba(0, 0, 0, 0.4) 3px);
  position: relative;
}
.home-header__contents {
  width: 100%;
  max-width: 1280px;
  padding: 128px 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  justify-content: center;
}
.home-header__name {
  padding-left: 64px;
  border-bottom: 2px solid var(--light3);
  position: relative;
}
.home-header__name:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 48px;
  height: 4px;
  background-color: var(--light3);
}
.home-header__name h1 {
  font-size: 12em;
  font-weight: 200;
  line-height: 1.1em;
  text-align: right;
}
.home-header__title {
  font-size: 1.2em;
  margin: 64px 0;
  border: 1px solid var(--dark4);
}
.home-header__title h3 {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border-bottom: 1px solid var(--dark4);
  padding: 16px 16px;
  font-size: 1.4em;
  letter-spacing: 0.5px;
  font-weight: lighter;
  position: relative;
}
.home-header__logo {
  display: inline-block;
  height: 32px;
  margin-right: 16px;
  filter: brightness(100);
}
.home-header__title p {
  padding: 12px 16px;
  font-weight: bold;
  text-transform: uppercase;
  background-color: var(--dark4);
}
.home-header__buttons {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding-bottom: 64px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}
.home-header__contact {
  border-radius: 0;
  color: var(--dark1);
  font-size: 1.5em;
  padding: 12px 48px;
  font-family: 'Space Mono', monospace;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.1s;
  display: flex;
  position: relative;
  align-items: center;
  border: 0;
  background-color: var(--light3);
}
.home-header__contact img {
  height: 16px;
  margin-right: 16px;
  filter: invert(0.9);
  position: relative;
  left: -16px;
  transition: all 0.2s;
}
.home-header__contact span {
  position: relative;
  text-transform: uppercase;
  left: -16px;
  transition: all 0.2s;
}
.home-header__contact:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 8px;
  transition: width 0.2s;
  background: var(--yellow);
}
.home-header__contact:hover:before {
  width: 100%;
}
.home-header__contact:hover span {
  left: 0;
}
.home-header__contact:hover img {
  left: 0;
}

.pipeline__canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

@media all and (max-width: 1200px) {
  .home-header__name h1 {
    font-size: 10em;
  }
  .home-header__contents {
    align-items: center;
  }
  .home-header__buttons {
    position: static;
    padding: 0;
  }
}

@media all and (max-width: 600px) {
  .home-header {
    height: 100vh;
  }
  .home-header__name h1 {
    font-size: 6em;
  }
}
